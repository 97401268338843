import React, { useState, useEffect } from 'react';
import {
    LineChart, Line, XAxis, YAxis, ResponsiveContainer,
    Tooltip, Legend, CartesianGrid, Bar, Area
} from 'recharts';
import { motion, AnimatePresence } from 'framer-motion';
import { Calendar, TrendingUp, TrendingDown } from 'lucide-react';

const CHART_COLORS = {
    revenue: {
        stroke: '#2563EB',
        fill: '#3B82F6',
        gradient: ['#3B82F620', '#3B82F600']
    },
    upcoming: {
        stroke: '#16A34A',
        fill: '#22C55E',
        gradient: ['#22C55E20', '#22C55E00']
    },
    cancelled: {
        stroke: '#DC2626',
        fill: '#EF4444',
        gradient: ['#EF444420', '#EF444400']
    },
    completed: {
        stroke: '#9333EA',
        fill: '#A855F7',
        gradient: ['#A855F720', '#A855F700']
    }
};

const VISUALIZATION_TYPES = {
    LINE: 'line',
    AREA: 'area',
    BAR: 'bar'
};

const MinimalTrendsChart = ({ data, selectedDateRange, onRangeChange }) => {
    const [hoveredData, setHoveredData] = useState(null);
    const [chartData, setChartData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [visualType, setVisualType] = useState(VISUALIZATION_TYPES.LINE);

    useEffect(() => {
        setIsLoading(true);
        // Add date range filtering here
        const filteredData = processBookings(data, selectedDateRange); // Pass selectedDateRange
        setChartData(filteredData);
        setIsLoading(false);
    }, [data, selectedDateRange]);  // Make sure selectedDateRange is in dependencies

    const processBookings = (bookings, range) => {
        if (!bookings?.length) return [];

        const now = new Date();
        let startDate = new Date();

        // Set proper date range
        switch (range) {
            case 'week':
                startDate.setDate(now.getDate() - 7);
                break;
            case 'month':
                startDate.setMonth(now.getMonth() - 1);
                break;
            case 'year':
                startDate.setFullYear(now.getFullYear() - 1);
                break;
            default:
                startDate.setDate(now.getDate() - 7);
        }

        // Filter bookings by date range first
        const rangeFilteredBookings = bookings.filter(booking => {
            const bookingDate = new Date(booking.selectedDate);
            return bookingDate >= startDate && bookingDate <= now;
        });
        
        const timeMap = new Map();
        const sortedBookings = [...bookings].sort((a, b) => 
            new Date(a.selectedDate) - new Date(b.selectedDate)
        );
        
        // Initialize dates
        const endDate = new Date(sortedBookings[sortedBookings.length - 1]?.selectedDate || new Date());
        
        for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
            const dateStr = d.toISOString().split('T')[0];
            timeMap.set(dateStr, {
                date: dateStr,
                upcoming: 0,
                cancelled: 0,
                completed: 0,
                revenue: 0,
                totalBookings: 0
            });
        }
        
        // Process bookings
        sortedBookings.forEach(booking => {
            const dateKey = booking.selectedDate;
            const dayStats = timeMap.get(dateKey) || {
                date: dateKey,
                upcoming: 0,
                cancelled: 0,
                completed: 0,
                revenue: 0,
                totalBookings: 0
            };
            
            dayStats.totalBookings++;
            
            // Calculate status
            if (booking.status === 'cancelled' || booking.cancellationReason) {
                dayStats.cancelled++;
            } else if (new Date(booking.selectedDate) > new Date()) {
                dayStats.upcoming++;
            } else {
                dayStats.completed++;
            }
            
            // Calculate revenue
            if (booking.selectedServices && booking.status !== 'cancelled') {
                const bookingRevenue = booking.selectedServices.reduce((sum, service) => 
                    sum + (parseFloat(service.price) || 0), 0);
                dayStats.revenue += bookingRevenue;
            }
            
            timeMap.set(dateKey, dayStats);
        });

        return Array.from(timeMap.values())
            .sort((a, b) => new Date(a.date) - new Date(b.date))
            .map(day => ({
                ...day,
                date: new Date(day.date).toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric'
                })
            }));
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (!active || !payload) return null;

        return (
            <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-white rounded-lg shadow-lg p-4 border border-gray-100"
            >
                <p className="font-semibold text-gray-900 mb-2">{label}</p>
                <div className="space-y-1">
                    {payload.map((entry, index) => (
                        <div key={index} className="flex items-center gap-2">
                            <div 
                                className="w-2 h-2 rounded-full"
                                style={{ backgroundColor: entry.stroke }}
                            />
                            <span className="text-gray-600 text-sm">
                                {entry.name}:
                            </span>
                            <span className="font-medium text-gray-900">
                                {entry.name === 'Revenue' ? `€${entry.value}` : entry.value}
                            </span>
                        </div>
                    ))}
                </div>
            </motion.div>
        );
    };

//     const renderRangeButton = (range, label) => (
//         <button
//             onClick={() => onRangeChange(range)}
//             className={`px-4 py-2 rounded-lg font-medium transition-all
// ${selectedDateRange === range
//     ? 'bg-blue-500 text-white shadow-md'
//     : 'bg-gray-100 text-gray-600 hover:bg-gray-200'}`}
//         >
//             {label}
//         </button>
//     );

    const renderChartLines = () => {
        const commonProps = {
            strokeWidth: 2,
            dot: false,
            activeDot: { r: 6, strokeWidth: 0 },
            animationDuration: 1000
        };

        switch(visualType) {
            case VISUALIZATION_TYPES.AREA:
                return (
                    <>
                        <defs>
                            {Object.entries(CHART_COLORS).map(([key, colors]) => (
                                <linearGradient
                                    key={key}
                                    id={`gradient-${key}`}
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1"
                                >
                                    <stop offset="5%" stopColor={colors.fill} stopOpacity={0.2} />
                                    <stop offset="95%" stopColor={colors.fill} stopOpacity={0} />
                                </linearGradient>
                            ))}
                        </defs>
                        <Area
                            yAxisId="right"
                            type="monotone"
                            dataKey="revenue"
                            stroke={CHART_COLORS.revenue.stroke}
                            fill={`url(#gradient-revenue)`}
                            name="Revenue"
                            {...commonProps}
                        />
                        <Area
                            yAxisId="left"
                            type="monotone"
                            dataKey="completed"
                            stroke={CHART_COLORS.completed.stroke}
                            fill={`url(#gradient-completed)`}
                            name="Completed"
                            {...commonProps}
                        />
                        <Area
                            yAxisId="left"
                            type="monotone"
                            dataKey="cancelled"
                            stroke={CHART_COLORS.cancelled.stroke}
                            fill={`url(#gradient-cancelled)`}
                            name="Cancelled"
                            {...commonProps}
                        />
                        <Area
                            yAxisId="left"
                            type="monotone"
                            dataKey="upcoming"
                            stroke={CHART_COLORS.upcoming.stroke}
                            fill={`url(#gradient-upcoming)`}
                            name="Upcoming"
                            {...commonProps}
                        />
                    </>
                );

            case VISUALIZATION_TYPES.BAR:
                return (
                    <>
                        <Bar
                            yAxisId="right"
                            dataKey="revenue"
                            fill={CHART_COLORS.revenue.fill}
                            name="Revenue"
                            radius={[4, 4, 0, 0]}
                        />
                        <Bar
                            yAxisId="left"
                            dataKey="completed"
                            fill={CHART_COLORS.completed.fill}
                            name="Completed"
                            radius={[4, 4, 0, 0]}
                        />
                        <Bar
                            yAxisId="left"
                            dataKey="cancelled"
                            fill={CHART_COLORS.cancelled.fill}
                            name="Cancelled"
                            radius={[4, 4, 0, 0]}
                        />
                        <Bar
                            yAxisId="left"
                            dataKey="upcoming"
                            fill={CHART_COLORS.upcoming.fill}
                            name="Upcoming"
                            radius={[4, 4, 0, 0]}
                        />
                    </>
                );

            default:
                return (
                    <>
                        <Line
                            yAxisId="right"
                            type="monotone"
                            dataKey="revenue"
                            stroke={CHART_COLORS.revenue.stroke}
                            name="Revenue"
                            {...commonProps}
                            strokeWidth={3}
                            dot={false}
                            activeDot={{
                                r: 8,
                                fill: CHART_COLORS.revenue.fill,
                                strokeWidth: 0
                            }}
                        />
                        <Line
                            yAxisId="left"
                            type="monotone"
                            dataKey="completed"
                            stroke={CHART_COLORS.completed.stroke}
                            name="Completed"
                            {...commonProps}
                            strokeWidth={3}
                            dot={false}
                            activeDot={{
                                r: 8,
                                fill: CHART_COLORS.completed.fill,
                                strokeWidth: 0
                            }}
                        />
                        <Line
                            yAxisId="left"
                            type="monotone"
                            dataKey="cancelled"
                            stroke={CHART_COLORS.cancelled.stroke}
                            name="Cancelled"
                            {...commonProps}
                            strokeWidth={3}
                            dot={false}
                            activeDot={{
                                r: 8,
                                fill: CHART_COLORS.cancelled.fill,
                                strokeWidth: 0
                            }}
                        />
                        <Line
                            yAxisId="left"
                            type="monotone"
                            dataKey="upcoming"
                            stroke={CHART_COLORS.upcoming.stroke}
                            name="Upcoming"
                            {...commonProps}
                            strokeWidth={3}
                            dot={false}
                            activeDot={{
                                r: 8,
                                fill: CHART_COLORS.upcoming.fill,
                                strokeWidth: 0
                            }}
                        />
                    </>
                );
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white rounded-xl shadow-xl p-6 space-y-6"
        >
            {/* Header */}
            {/* Header */}
            <div className="flex justify-between items-center">
                <div>
                    <h3 className="text-xl font-bold text-gray-900">Booking Analytics</h3>
                    <p className="text-gray-500 text-sm">Tracking performance over time</p>
                </div>

                <div className="flex items-center gap-4">
                    <select
                        value={visualType}
                        onChange={(e) => setVisualType(e.target.value)}
                        className="select select-sm select-bordered font-medium"
                    >
                        <option value={VISUALIZATION_TYPES.LINE}>Smooth Lines</option>
                        <option value={VISUALIZATION_TYPES.AREA}>Area Chart</option>
                        <option value={VISUALIZATION_TYPES.BAR}>Bar Chart</option>
                    </select>
                </div>
            </div>

            {/* Stats Summary */}
            <div className="grid grid-cols-4 gap-4">
                {hoveredData && (
                    <AnimatePresence mode="wait">
                        <motion.div
                            key={hoveredData.date}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="col-span-4 p-4 bg-gray-50 rounded-lg"
                        >
                            <div className="flex justify-between items-center">
                                <div className="flex items-center gap-2">
                                    <Calendar className="w-4 h-4 text-blue-500" />
                                    <span className="font-medium">{hoveredData.date}</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    {hoveredData.revenue > 0 ? (
                                        <TrendingUp className="w-4 h-4 text-green-500" />
                                    ) : (
                                        <TrendingDown className="w-4 h-4 text-red-500" />
                                    )}
                                    <span className="font-medium">
                                        €{hoveredData.revenue}
                                    </span>
                                </div>
                            </div>
                        </motion.div>
                    </AnimatePresence>
                )}
            </div>

            {/* Chart */}
            <div className="h-[400px] w-full">
                <ResponsiveContainer>
                    <LineChart
                        data={chartData}
                        margin={{ top: 20, right: 30, left: 10, bottom: 5 }}
                        onMouseMove={(e) => {
                            if (e.activePayload) {
                                setHoveredData(e.activePayload[0].payload);
                            }
                        }}
                        onMouseLeave={() => setHoveredData(null)}
                    >
                        <CartesianGrid
                            strokeDasharray="3 3"
                            stroke="#E5E7EB"
                            vertical={false}
                            opacity={0.5}
                        />

                        <XAxis
                            dataKey="date"
                            stroke="#6B7280"
                            fontSize={12}
                            tickLine={false}
                            axisLine={false}
                            padding={{ left: 10, right: 10 }}
                        />

                        <YAxis
                            yAxisId="left"
                            stroke="#6B7280"
                            fontSize={12}
                            tickLine={false}
                            axisLine={false}
                            domain={[0, 'auto']}
                            padding={{ top: 20 }}
                        />

                        <YAxis
                            yAxisId="right"
                            orientation="right"
                            stroke="#2563EB"
                            fontSize={12}
                            tickLine={false}
                            axisLine={false}
                            tickFormatter={value => `€${value}`}
                            domain={[0, 'auto']}
                            padding={{ top: 20 }}
                        />

                        <Tooltip
                            content={<CustomTooltip />}
                            cursor={{ stroke: '#6B7280', strokeWidth: 1, strokeDasharray: '4 4' }}
                        />

                        <Legend
                            verticalAlign="top"
                            height={36}
                            iconType="circle"
                            iconSize={8}
                            formatter={(value) => (
                                <span className="text-sm font-medium text-gray-600">{value}</span>
                            )}
                        />

                        {renderChartLines()}
                    </LineChart>
                </ResponsiveContainer>
            </div>

            {/* Loading State */}
            {isLoading && (
                <div className="absolute inset-0 bg-white/80 flex items-center justify-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
                </div>
            )}
        </motion.div>
    );
};

export default MinimalTrendsChart;
