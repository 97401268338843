import React, { useState, useRef, useEffect } from 'react';
import {
    ChevronRight, Calendar as CalIcon,
    Gift, Tag, Coffee, Star, AlertCircle,
    TrendingUp, Users, DollarSign, Clock,
    Calendar, ChevronLeft
} from 'lucide-react';

const BarberCalendar = ({ availability, specialDates, setSpecialDates, t }) => {
    const [selectedMonth, setSelectedMonth] = useState(new Date());
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState(null);
    const [dragEnd, setDragEnd] = useState(null);
    const [selectedDates, setSelectedDates] = useState([]);
    const [editMode, setEditMode] = useState('regular');
    const [selectionFields, setSelectionFields] = useState([]);
    const [isMobile, setIsMobile] = useState(false);
    const [mobileSelection, setMobileSelection] = useState({ start: null, end: null });
    const [showMobileConfirm, setShowMobileConfirm] = useState(false);
    const calendarRef = useRef(null);
    const today = new Date();
    const [isExpanded, setIsExpanded] = useState(false);

    if (typeof setSpecialDates !== 'function') {
        console.error('setSpecialDates must be a function');
        // Provide a no-op function as fallback
        setSpecialDates = () => {};
    }

    // Detect mobile device
    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const modeColors = {
        regular: 'bg-primary/20 hover:bg-primary/30',
        holiday: 'bg-red-200 hover:bg-red-300',
        special: 'bg-yellow-200 hover:bg-yellow-300',
        promo: 'bg-green-200 hover:bg-green-300'
    };

    const modeIcons = {
        regular: CalIcon,
        holiday: Coffee,
        special: Star,
        promo: Tag
    };

    const getDaysInMonth = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        const days = [];

        for (let i = 0; i < firstDay.getDay(); i++) {
            days.push(null);
        }

        for (let i = 1; i <= lastDay.getDate(); i++) {
            days.push(new Date(year, month, i));
        }

        return days;
    };

    const formatDate = (date) => {
        return date ? date.toISOString().split('T')[0] : '';
    };

    const isDateInRange = (date, start, end) => {
        if (!start || !end || !date) return false;
        const startTime = new Date(Math.min(start.getTime(), end.getTime()));
        const endTime = new Date(Math.max(start.getTime(), end.getTime()));
        return date >= startTime && date <= endTime;
    };

    // Mobile-specific date selection handler
    const handleMobileDateSelect = (date) => {
        if (!date || date < today) return;

        if (!mobileSelection.start) {
            setMobileSelection({ start: date, end: null });
        } else if (!mobileSelection.end) {
            if (date < mobileSelection.start) {
                setMobileSelection(prev => ({ ...prev, end: prev.start, start: date }));
            } else {
                setMobileSelection(prev => ({ ...prev, end: date }));
            }
            setShowMobileConfirm(true);
        } else {
            setMobileSelection({ start: date, end: null });
            setShowMobileConfirm(false);
        }
    };

    // Desktop drag selection handlers
    const handleMouseDown = (date) => {
        if (!date || date < today || isMobile) return;
        setIsDragging(true);
        setDragStart(date);
        setDragEnd(date);
    };

    const handleMouseMove = (date) => {
        if (isDragging && date) {
            setDragEnd(date);
        }
    };

    const handleSelectionComplete = (start, end) => {
        if (!start || !end) return;

        const startDate = new Date(Math.min(start.getTime(), end.getTime()));
        const endDate = new Date(Math.max(start.getTime(), end.getTime()));

        // Check for overlapping dates
        let isOverlapping = false;
        Object.entries(specialDates || {}).forEach(([existingStartDate, data]) => {
            const existingStart = new Date(existingStartDate);
            const existingEnd = new Date(data.endDate);
            if (startDate <= existingEnd && endDate >= existingStart) {
                isOverlapping = true;
            }
        });

        if (!isOverlapping) {
            const newField = {
                start: startDate,
                end: endDate,
                type: editMode
            };

            setSelectionFields(prev => [...prev, newField]);
            setSpecialDates(prev => {
                const newDates = {
                    ...prev,
                    [formatDate(newField.start)]: {
                        type: newField.type,
                        endDate: formatDate(newField.end)
                    }
                };
                console.log('Updated Special Dates:', newDates); // Add this line
                return newDates;
            });
        }
    };

    const handleMouseUp = () => {
        if (isDragging && dragStart && dragEnd) {
            handleSelectionComplete(dragStart, dragEnd);
        }
        setIsDragging(false);
        setDragStart(null);
        setDragEnd(null);
    };

    // Handle mobile selection confirmation
    const handleMobileConfirm = () => {
        if (mobileSelection.start && mobileSelection.end) {
            handleSelectionComplete(mobileSelection.start, mobileSelection.end);
            setMobileSelection({ start: null, end: null });
            setShowMobileConfirm(false);
        }
    };

    useEffect(() => {
        if (specialDates) {
            const fields = Object.entries(specialDates).map(([startDate, data]) => ({
                start: new Date(startDate),
                end: new Date(data.endDate),
                type: data.type
            }));
            setSelectionFields(fields);
        }

        // Add click outside listener for desktop
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                handleMouseUp();
            }
        };

        document.addEventListener('mouseup', handleClickOutside);
        return () => document.removeEventListener('mouseup', handleClickOutside);
    }, []);

    const handleRemoveSelection = (index, field) => {
        setSelectionFields(prev => prev.filter((_, i) => i !== index));
        setSpecialDates(prev => {
            const newDates = {...prev};
            delete newDates[formatDate(field.start)];
            return newDates;
        });
    };

    const getAnalytics = () => {
        const total = selectionFields.length;
        const byType = {
            holiday: selectionFields.filter(f => f.type === 'holiday').length,
            special: selectionFields.filter(f => f.type === 'special').length,
            promo: selectionFields.filter(f => f.type === 'promo').length
        };
        return { total, byType };
    };

    const analytics = getAnalytics();

    return (
        <div className="space-y-6 w-full max-w-4xl mx-auto">
            {/* Calendar Controls */}
            <div className="flex flex-col sm:flex-row justify-between items-center gap-4 bg-base-100 p-4 rounded-lg shadow-lg">
                <div className="flex items-center gap-4">
                    <button
                        className="btn btn-circle btn-sm"
                        onClick={() => {
                            const newDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() - 1);
                            if (newDate.getMonth() >= today.getMonth() && newDate.getFullYear() >= today.getFullYear()) {
                                setSelectedMonth(newDate);
                            }
                        }}
                        disabled={selectedMonth.getMonth() === today.getMonth() && selectedMonth.getFullYear() === today.getFullYear()}
                    >
                        <ChevronLeft className="w-4 h-4"/>
                    </button>
                    <h3 className="text-xl font-bold">
                        {months[selectedMonth.getMonth()]} {selectedMonth.getFullYear()}
                    </h3>
                    <button
                        className="btn btn-circle btn-sm"
                        onClick={() => setSelectedMonth(new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1))}
                    >
                        <ChevronRight className="w-4 h-4"/>
                    </button>
                </div>

                <div className="join shadow-lg">
                    {Object.entries(modeIcons).map(([mode, Icon]) => (
                        <button
                            key={mode}
                            className={`join-item btn btn-sm gap-2 ${editMode === mode ? 'btn-primary' : 'btn-ghost'}
                            transition-all duration-300 hover:scale-105`}
                            onClick={() => setEditMode(mode)}
                        >
                            <Icon className="w-4 h-4"/>
                            <span className="capitalize">{mode}</span>
                        </button>
                    ))}
                </div>
            </div>

            {/* Mobile Selection Instructions */}
            {isMobile && (
                <div className="bg-base-100 p-4 rounded-lg shadow-lg text-center">
                    <p className="text-sm text-gray-600">
                        {!mobileSelection.start
                            ? "Tap to select start date"
                            : !mobileSelection.end
                                ? "Tap to select end date"
                                : "Confirm your selection"}
                    </p>
                </div>
            )}

            {/* Calendar Grid */}
            <div
                ref={calendarRef}
                className="select-none bg-base-100 rounded-lg shadow-xl p-4"
                onMouseLeave={!isMobile ? handleMouseUp : undefined}
            >
                <div className="grid grid-cols-7 gap-2 mb-4">
                    {weekDays.map(day => (
                        <div key={day} className="text-center font-semibold text-gray-500">
                            {day}
                        </div>
                    ))}
                </div>

                <div className="grid grid-cols-7 gap-2">
                    {getDaysInMonth(selectedMonth).map((date, index) => {
                        const isInDragRange = !isMobile && isDragging && date && isDateInRange(date, dragStart, dragEnd);
                        const isInMobileRange = isMobile && date && isDateInRange(date, mobileSelection.start, mobileSelection.end);
                        const existingSelections = selectionFields.filter(field =>
                            date && isDateInRange(date, field.start, field.end)
                        );
                        const isPast = date && date < today;
                        const isMobileStart = isMobile && date && mobileSelection.start &&
                            date.getTime() === mobileSelection.start.getTime();
                        const isMobileEnd = isMobile && date && mobileSelection.end &&
                            date.getTime() === mobileSelection.end.getTime();

                        return (
                            <div
                                key={index}
                                className={`
                                    relative aspect-square flex items-center justify-center
                                    transition-all duration-200 text-base
                                    ${date ? 'cursor-pointer hover:scale-105' : ''}
                                    ${isPast ? 'opacity-50 cursor-not-allowed' : ''}
                                    ${isInDragRange || isInMobileRange ? modeColors[editMode] : ''}
                                    ${existingSelections.length > 0 ? modeColors[existingSelections[0].type] : ''}
                                    ${isMobileStart || isMobileEnd ? 'ring-2 ring-primary' : ''}
                                    rounded-lg shadow-sm hover:shadow-md
                                    ${isMobile ? 'touch-manipulation' : ''}
                                `}
                                onMouseDown={() => !isMobile && handleMouseDown(date)}
                                onMouseMove={() => !isMobile && handleMouseMove(date)}
                                onMouseUp={() => !isMobile && handleMouseUp()}
                                onClick={() => isMobile && handleMobileDateSelect(date)}
                            >
                                {date && (
                                    <>
                                        <span className={`
                                            ${isPast ? 'text-gray-400' : ''}
                                            ${existingSelections.length > 0 ? 'font-bold' : ''}
                                        `}>
                                            {date.getDate()}
                                        </span>
                                        {existingSelections.length > 0 && (
                                            <div className="absolute -top-1 -right-1">
                                                {React.createElement(modeIcons[existingSelections[0].type], {
                                                    className: 'w-3 h-3'
                                                })}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>

            {/* Mobile Confirmation Button */}
            {isMobile && showMobileConfirm && (
                <div className="fixed bottom-4 left-0 right-0 flex justify-center z-50 px-4">
                    <button
                        className="btn btn-primary btn-lg w-full max-w-md shadow-lg"
                        onClick={handleMobileConfirm}
                    >
                        Confirm Selection
                    </button>
                </div>
            )}

            {/* Analytics Dashboard */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <div className="stat bg-base-100 rounded-lg shadow-lg p-4">
                    <div className="stat-figure text-primary">
                        <Calendar className="w-8 h-8" />
                    </div>
                    <div className="stat-title">Total Selections</div>
                    <div className="stat-value">{analytics.total}</div>
                </div>

                <div className="stat bg-base-100 rounded-lg shadow-lg p-4">
                    <div className="stat-figure text-red-500">
                        <Coffee className="w-8 h-8" />
                    </div>
                    <div className="stat-title">Holidays</div>
                    <div className="stat-value text-red-500">{analytics.byType.holiday}</div>
                </div>

                <div className="stat bg-base-100 rounded-lg shadow-lg p-4">
                    <div className="stat-figure text-yellow-500">
                        <Star className="w-8 h-8" />
                    </div>
                    <div className="stat-title">Special Days</div>
                    <div className="stat-value text-yellow-500">{analytics.byType.special}</div>
                </div>

                <div className="stat bg-base-100 rounded-lg shadow-lg p-4">
                    <div className="stat-figure text-green-500">
                        <Tag className="w-8 h-8" />
                    </div>
                    <div className="stat-title">Promo Days</div>
                    <div className="stat-value text-green-500">{analytics.byType.promo}</div>
                </div>
            </div>

            {/* Selection List - Enhanced UI */}
            {/* Selection List - Floating Panel */}
            {selectionFields.length > 0 && (
                <div className={`
        fixed left-0 right-0 z-50 p-4 transition-transform duration-300 ease-in-out transform
        ${isMobile ? 'bottom-20' : 'bottom-0'}
    `}>
                    <div className="max-w-4xl mx-auto">
                        <div className="bg-base-100 rounded-t-xl shadow-2xl border border-base-200">
                            <div
                                className="p-3 cursor-pointer flex items-center justify-between hover:bg-base-200/50 transition-colors duration-200"
                                onClick={() => setIsExpanded(!isExpanded)}
                            >
                                <div className="flex items-center gap-2">
                                    <Calendar className="w-5 h-5 text-primary" />
                                    <h3 className="text-lg font-bold">
                                        Selected Periods ({selectionFields.length})
                                    </h3>
                                </div>
                                <ChevronRight
                                    className={`w-5 h-5 transition-transform duration-300 ${
                                        isExpanded ? 'rotate-90' : ''
                                    }`}
                                />
                            </div>

                            <div className={`
                    overflow-hidden transition-all duration-300 ease-in-out
                    ${isExpanded ? 'max-h-96' : 'max-h-0'}
                `}>
                                <div className="p-4 space-y-2 max-h-72 overflow-y-auto">
                                    {selectionFields.map((field, index) => (
                                        <div
                                            key={index}
                                            className={`
                                    flex items-center justify-between p-3
                                    ${modeColors[field.type]}
                                    rounded-lg transition-all duration-200
                                    hover:shadow-md hover:scale-[1.02]
                                    animate-fadeIn
                                `}
                                        >
                                            <div className="flex items-center gap-3">
                                                {React.createElement(modeIcons[field.type], {
                                                    className: 'w-5 h-5'
                                                })}
                                                <span className="font-medium">
                                        {field.start.toLocaleDateString()} - {field.end.toLocaleDateString()}
                                    </span>
                                            </div>
                                            <button
                                                className="btn btn-ghost btn-sm btn-circle hover:bg-red-100 hover:text-red-500
                                             transition-all duration-200 hover:rotate-90"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRemoveSelection(index, field);
                                                }}
                                                title="Remove selection"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-5 w-5"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <style jsx>{`
            @keyframes fadeIn {
                from { opacity: 0; transform: translateY(10px); }
                to { opacity: 1; transform: translateY(0); }
            }
            
            .animate-fadeIn {
                animation: fadeIn 0.3s ease-out;
            }
            
            /* Custom scrollbar */
            .overflow-y-auto {
                scrollbar-width: thin;
                scrollbar-color: rgba(0,0,0,0.2) transparent;
            }
            
            .overflow-y-auto::-webkit-scrollbar {
                width: 6px;
            }
            
            .overflow-y-auto::-webkit-scrollbar-track {
                background: transparent;
            }
            
            .overflow-y-auto::-webkit-scrollbar-thumb {
                background-color: rgba(0,0,0,0.2);
                border-radius: 3px;
            }
            
            @media (max-width: 768px) {
                .max-h-72 {
                    max-height: 40vh;
                }
            }
        `}</style>
                </div>
            )}

            {/* Touch Event Styles for Mobile */}
            <style jsx>{`
                @media (max-width: 768px) {
                    .touch-manipulation {
                        touch-action: manipulation;
                        -webkit-tap-highlight-color: transparent;
                    }
                }

                @keyframes pulse {
                    0% { transform: scale(1); }
                    50% { transform: scale(1.05); }
                    100% { transform: scale(1); }
                }
            `}</style>
        </div>
    );
};

export default BarberCalendar;